import React from 'react';
import { node, string } from 'prop-types';
import dynamic from 'next/dynamic';

const MuiButton = dynamic(() => import('@mui/material/Button'));

const Button = ({ href, children, ...rest }) => {
  const getHref = () => {
    if (typeof window !== 'undefined') {
      if (!href) return '';
      if (href.includes('tel') || href.includes('mailto')) return href;
      return `${href}${typeof window !== 'undefined' ? window.location.search : ''}`;
    }
    return '';
  };

  return (
    <MuiButton href={getHref(href)} {...rest}>
      {children && children}
    </MuiButton>
  );
};

Button.propTypes = {
  href: string,
  children: node,
};

Button.defaultProps = {
  href: null,
  children: null,
};

export default Button;
